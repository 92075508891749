// extracted by mini-css-extract-plugin
export var title = "posfeatures-module--title--2oaxc";
export var leadContainer = "posfeatures-module--leadContainer--2cVWT";
export var cover = "posfeatures-module--cover--2lvFJ";
export var leadtitle = "posfeatures-module--leadtitle--1t3wY";
export var leaddescription = "posfeatures-module--leaddescription--2YdWp";
export var featurestitle = "posfeatures-module--featurestitle--m2RsD";
export var featureContainer = "posfeatures-module--featureContainer--1VOBV";
export var featuretitle = "posfeatures-module--featuretitle--aWyV6";
export var featuredescription = "posfeatures-module--featuredescription---zpxn";
export var featureimage = "posfeatures-module--featureimage--3dg_L";
export var features = "posfeatures-module--features--1Eut-";
export var contact = "posfeatures-module--contact--2M8qu";